<template>
    <section class='work-intro' v-if='intro.length > 0'>
        <div class='detail' 
            :class=[col.slug]
            v-for='col in intro' :key='col.id'>
            <h4 class='title theme-dark' v-html='col.title'/>
            <ul class='list' v-if='col.list'>
                <li class='theme-dark' v-for='text in col.list' :key='text'>
                    {{text}}
                </li>
            </ul>
            <p class='text theme-dark' v-if='col.text' v-html='col.text'/>
        </div>
    </section>  
</template>

<script>
export default {
    name: 'work-intro',
    props: {
        intro: {
            type: Array
        }
    },
    watch: {
        $route() {
            this.$nextTick(this.setColumns);
        }
    },
    methods: {
        setColumns() {
            const {length} = this.intro;
            if (window.innerWidth > 768 && length > 0) {
                const colums = length - 1 > 3 ? 2 : length - 1;
                this.$el.style.setProperty('--intro-length', colums);
            }
        }
    },
    mounted() {
        this.$nextTick(this.setColumns);
    }
}
</script>

<style lang='scss'>

@import '../styles/mixins';

/*
Work Intro 
.....................................
*/

.work-intro {
    --intro-length: 2;
    display: grid;
    grid-template-columns: 2fr repeat(var(--intro-length), 1fr);
    justify-content: flex-start;
    align-self: flex-start;
    padding: vw(var(--gutter));
    width: 100%;
    grid-gap: vw(calc(var(--gutter) / 2));
    @include bp(tablet) {
        grid-gap: vh(45) vw(var(--gutter));
        width: 100%;
    }
    @include bp(mobile) {
        padding-top: 30px;
        grid-template-columns: 1fr;
    }
}

.work-intro .detail .title {
    margin-bottom: vh(15);
}

.work-intro .detail .list {
    list-style: none;
    padding: 0;
}

</style>

