<template>
  <div class="work-text" :style="text.style">
    <h3
      v-if="text.title"
      class="font-weight-bold work-section-title theme-dark"
      v-html="text.title"
    />
    <h4
      v-if="text.subtitle"
      class="font-weight-bold work-section-title theme-dark"
      v-html="text.subtitle"
    />
    <p
      v-if="text.summary"
      class="work-summary font-weight-regular theme-dark"
      v-html="text.summary"
    />
  </div>
</template>

<script>
export default {
  name: "work-text",
  props: {
    text: {
      type: Object,
      default() {
        return {
          title: null,
          subtitle: null,
          summary: null,
          style: null,
        };
      },
    },
  },
};
</script>
