<template>
  <section class="work-hero">
    <header class="work-header">
      <div class="work-title">
        <h1 class="theme-dark">{{ project.title }}</h1>
        <p class="work-subtitle font-weight-regular theme-dark">
          {{ project.subtitle }}
        </p>
      </div>
      <aside class="work-aside" v-show="project.projectUrl">
        <AppButton icon="arrow-top-right">
          <a
            class="work-project-link font-weight-regular link theme-dark"
            :href="project.projectUrl"
            target="_blank"
            slot="outer"
            rel="noopener"
          />
          Live Project
        </AppButton>
      </aside>
    </header>
    <figure class="work-hero-images">
      <ParallaxContainer
        tag="picture"
        class="work-thumb-image"
        :class="{
          tall: $store.getters.thumbSize($route.params.id),
        }"
        :style="{ backgroundColor: 'var(--theme-dark)' }"
        v-if="thumbImage.src"
      >
        <source
          v-for="img in thumbSources"
          :key="img.type"
          :srcset="img.src"
          :type="img.type"
        />
        <ParallaxElement tag="img" :src="thumbImage.src" />
      </ParallaxContainer>
      <ParallaxContainer
        tag="picture"
        class="work-poster-image"
        :style="{ backgroundColor: 'var(--theme-dark)' }"
        v-if="heroImage.src"
      >
        <source
          v-for="img in heroSources"
          :key="img.type"
          :srcset="img.src"
          :type="img.type"
        />
        <ParallaxElement tag="img" :src="heroImage.src" />
      </ParallaxContainer>
    </figure>
  </section>
</template>

<script>
import AppButton from "@/components/AppButton";
import ParallaxContainer from "@/components/ParallaxContainer";
import ParallaxElement from "@/components/ParallaxElement";
import toJPG from "@/utils/toJPG";

export default {
  name: "work-hero",
  components: {
    AppButton,
    ParallaxContainer,
    ParallaxElement,
  },
  props: {
    project: {
      type: Object,
    },
  },
  computed: {
    heroImage() {
      return this.heroSources[1];
    },
    heroSources() {
      return this.makeSources("hero");
    },
    thumbImage() {
      return this.thumbSources[1];
    },
    thumbSources() {
      return this.makeSources("thumb");
    },
  },
  methods: {
    makeSources(prop) {
      return [
        {
          type: "image/webp",
          src: this.project[prop].src,
        },
        {
          type: "image/jpeg",
          src: toJPG(this.project[prop].src),
        },
      ];
    },
  },
};
</script>

<style lang="scss">
@import "../styles/mixins";

.work-hero {
  --pt-scale: 2;
  @include bp(tablet) {
    --pt-scale: 4;
  }
  @include bp(mobile) {
    --pt-scale: 6;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  position: relative;
}

.work-hero .work-header {
  position: relative;
  z-index: 1;
  display: grid;
  padding-left: vw(280);
  padding-left: vw(var(--gutter));
  padding-right: vw(var(--gutter));
  padding-top: calc(var(--gutted) * var(--pt-scale));
  margin-bottom: vh(90);
  @include bp(tablet) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  @include bp(mobile) {
    flex-direction: column;
    align-items: flex-start;
  }
  h1 {
    margin: 0;
  }
}

.work-hero .work-subtitle {
  margin-top: vw(15);
  margin-bottom: 0;
}

.work-hero .work-hero-images {
  display: flex;
  padding: 0 var(--gutted);
  @include bp(mobile) {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
}

.work-hero .work-title {
  color: var(--theme-dark);
  padding-right: vw(90);
  @include bp(mobile) {
    padding-right: 0;
    margin-bottom: vw(15);
  }
}

.work-hero .work-aside {
  grid-column: 2/3;
  grid-row: 1/2;
  justify-self: flex-end;
  align-self: flex-end;
  @include bp(mobile) {
    align-self: flex-start;
  }
  .app-button:hover .app-icon {
    stroke: var(--theme-light);
  }
  .app-button:hover .char:after {
    color: var(--theme-light);
  }
  .app-button:after {
    background: var(--theme-dark);
  }
}

.work-hero .work-thumb-image {
  width: vw(330);
  height: vw(300);
  margin-right: vw(90);
  min-height: vw(200);
  overflow: hidden;
  height: vw(500);
  @include bp(tablet) {
    height: vw(400);
    margin-right: vw(45);
  }
  @include bp(mobile) {
    height: 70vh;
    min-height: 0;
    margin: -1px 0 vw(var(--gutter));
    padding: 0;
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
}

.work-hero .work-poster-image {
  position: relative;
  width: vw(750);
  height: vw(354);
  min-height: vw(350);
  overflow: hidden;
  margin-top: vw(90);
  img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  @include bp(tablet) {
    height: 30vw;
    margin-top: vw(60);
    width: calc(100vw - #{var(--gutted)});
  }
  @include bp(mobile) {
    width: 100%;
    align-self: initial;
    min-height: 200px;
    margin-top: 0;
  }
}
</style>
