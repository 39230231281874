<template>
    <ParallaxContainer class='work-images'>
        <figure
            class='work-image' 
            :class='{ 
                "is-video": isVideo(image.src),
                "has-alt-text": image.alt
            }'
            :style='image.style'
            v-for='(image, idx) in projectImages' 
            :key='randomId(idx)'>
            <DeviceContainer
                v-if='isVideo(image.src)'
                :placeholder='image.placeholder'
                :alt='image.alt'
                :autoplay='image.autoplay'
                :deviceType='image.deviceType'
                :src='image.src'/>
            <picture v-else>
                <source
                    v-for='img in image.sources'
                    :key='img.type'
                    :type='img.type'
                    :srcset='img.src'/>
                <ParallaxElement
                    tag='img'
                    :compensate='!image.factor ? false : true'
                    :factor='!image.factor ? 0 : image.factor'
                    loading='lazy'
                    :src='image.src' 
                    :alt='image.alt'/>
            </picture>   
            <figcaption
                v-if='image.alt'
                :class='image.caption && image.caption.class' 
                class='vertical-text theme-dark'>{{image.alt}}</figcaption>
        </figure>
    </ParallaxContainer>
</template>

<script>
import DeviceContainer from '@/components/DeviceContainer';
import ParallaxContainer from '@/components/ParallaxContainer';
import ParallaxElement from '@/components/ParallaxElement';
import randomId from '@/utils/randomId';
import isVideo from '@/utils/isVideo';
import toJPG from '@/utils/toJPG';

export default {
    name: 'work-images',
    components: {
        DeviceContainer,
        ParallaxContainer,
        ParallaxElement
    },
    props: {
        images: {
            type: Array,
            default: []
        }
    },
    computed: {
        projectImages() {
            return this.images.map(img => {
                return {
                    ...img,
                    src: toJPG(img.src),
                    sources: [{
                        type: 'image/webp',
                        src: img.src
                    }, {
                        type: 'image/jpeg',
                        src: toJPG(img.src)
                    }]
                };
            });
        }
    },
    methods: {
        isVideo,
        randomId
    }
}
</script>

<style lang='scss'>
    picture {
        width: 100%;
        img {
            display: block;
        }
    }
</style>